import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './messages';
export function Modal(props) {
  const { show, children, size, onCancel, isCloseButtonInside, noPadding } = props;

  const cancelFn = () => {
    if (onCancel) onCancel();
  };
  const sizeMap = {
    sm: 'max-w-lg',
    md: 'max-w-2xl',
    lg: 'max-w-4xl',
    xl: 'max-w-7xl',
  };
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={cancelFn}>
        <div
          className={classNames(
            'flex items-center justify-center min-h-screen',
            'text-center sm:block',
            !noPadding && 'pt-4 px-4 pb-20 sm:p-0',
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`relative inline-block align-bottom bg-white rounded-lg
              text-left shadow-xl transform transition-all sm:my-8 sm:align-middle
               ${get(sizeMap, size)}`}
            >
              <div className="">
                <div
                  className={classNames(
                    isCloseButtonInside
                      ? 'absolute top-0 right-0 pt-6 pr-6 lg:pt-8 lg:pr-8 md:pt-6 md:pr-6'
                      : 'absolute top-0 right-0 -mr-11 -mt-4 flex pt-4 pr-2 sm:-ml-8 md:-ml-2 sm:pr-4',
                  )}
                >
                  <button
                    type="button"
                    className={classNames(
                      isCloseButtonInside ? 'text-gray-400 rounded-md' : 'text-white rounded-md hover:text-gray-100',
                    )}
                    onClick={cancelFn}
                  >
                    <span className="sr-only">
                      <FormattedMessage {...messages.close} />
                    </span>
                    <FontAwesomeIcon icon={faXmark} className="w-6 h-6" />
                  </button>
                </div>
              </div>
              <>{children}</>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  isCloseButtonInside: PropTypes.bool,
};

Modal.defaultProps = {
  show: false,
  size: 'md',
};
export default memo(Modal);
