import LoginPage from 'containers/auth/LoginPage/Loadable';
import LogoutPage from 'containers/auth/LogoutPage/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import EvaluationsTable from 'containers/Evaluations/Loadable';
import EvaluatorInterviewDecline from 'containers/EvaluatorInterviewDecline/Loadable';
import EvaluatorInterviewProposeTime from 'containers/EvaluatorInterviewProposeTime/Loadable';
import InterviewerAvailability from 'containers/InterviewerAvailability/Loadable';
import InterviewerDashboard from 'containers/InterviewerDashboard/Loadable';
import Workflow from 'containers/WorkflowList/Loadable';
import EvaluatorAcceptProposedTime from 'containers/EvaluatorAcceptProposedTime/Loadable';
import OrganizerReschedule from 'containers/OrganizerReschedule/Loadable';

import { Forbidden, Error, NotFound } from 'components/pages';
import NewWorkflowDetails from 'containers/NewWorkflowDetails/Loadable';
import EvaluatorDeclineProposedNewTime from 'containers/EvaluatorDeclineProposedNewTime/Loadable';
import JoinInterview from 'containers/JoinInterview/Loadable';
import JoinDrive from 'containers/JoinDrive/Loadable';
import JobDescription from 'containers/JobDescription/Loadable';
import EvaluationDetailView from 'containers/EvaluationDetailView/Loadable';
import NotificationManagement from 'containers/NotificationManagement/Loadable';
import PreInterviewDashboard from 'containers/PreInterviewDashboard/Loadable';
import OrganizerChangeInterviewer from 'containers/OrganizerChangeInterviewer/Loadable';
import SlotBooking from 'containers/SlotBooking/Loadable';
import OrganizerCancel from 'containers/OrganizerCancel/Loadable';

export const publicRoutes = [
  { path: '/login', component: LoginPage },
  { path: '/logout', component: LogoutPage },
  { path: '/', component: LoginPage },
  { path: '/error', component: Error, skipTest: true },
  { path: '*', component: NotFound, skipTest: true, exact: false },
];

export const privateRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/workflow', component: Workflow },
  { path: '/workflow/:workflow_id', component: NewWorkflowDetails },
  { path: '/workflow/:workflow_id/invite/:step_id', component: NewWorkflowDetails },
  { path: '/workflow/:workflow_id/drive/:drive_id', component: NewWorkflowDetails },
  { path: '/forbidden', component: Forbidden, skipTest: true },
  { path: '/panel/decline-interview', component: EvaluatorInterviewDecline },
  { path: '/panel/propose-new-time', component: EvaluatorInterviewProposeTime },
  { path: '/panel/interviewer-dashboard', component: InterviewerDashboard },
  { path: '/panel/accept-proposed-time', component: EvaluatorAcceptProposedTime },
  { path: '/panel/calendar', component: InterviewerAvailability },
  { path: '/panel/decline-proposed-time', component: EvaluatorDeclineProposedNewTime },
  { path: '/panel/join-interview', component: JoinInterview },
  { path: '/panel/join-drive', component: JoinDrive },
  { path: '/job-description', component: JobDescription },
  { path: '/evaluations/:id', component: EvaluationDetailView },
  { path: '/evaluations', component: EvaluationsTable },
  { path: '/notification-inbox', component: NotificationManagement },
  { path: '/dashboard/pre-interview', component: PreInterviewDashboard },
  { path: '/organizer/change-interviewer', component: OrganizerChangeInterviewer },
  { path: '/organizer/reschedule', component: OrganizerReschedule },
  { path: '/organizer/cancel-interview', component: OrganizerCancel },

  { path: '/scheduling/slot-booking', component: SlotBooking },
];

export default [...publicRoutes, ...privateRoutes];
