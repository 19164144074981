import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.SideNavigation.RequestFeature';

export default defineMessages({
  request_feature: {
    id: `${scope}.request_feature`,
    defaultMessage: 'Request a Feature',
  },
  select_a_topic: {
    id: `${scope}.select_a_topic`,
    defaultMessage: 'Select a topic *',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  how_to_improve: {
    id: `${scope}.how_to_improve`,
    defaultMessage: 'How can we improve?*',
  },
  success_title: {
    id: `${scope}.success_title`,
    defaultMessage: 'Your request logged successfully',
  },
  error_message_title: {
    id: `${scope}.error_message_title`,
    defaultMessage: 'Uh-oh! Feature Request failed',
  },
  error_message_summary: {
    id: `${scope}.error_message_summary`,
    defaultMessage: 'Feature request did not go through as expected. Please try again after sometime. ',
  },
  success_summary: {
    id: `${scope}.success_summary`,
    defaultMessage: 'Thank you for submitting your valuable feedback. We will keep you posted on the same.',
  },
  helpful: {
    id: `${scope}.helpful`,
    defaultMessage: 'That was helpful!',
  },
  sharing_feedback: {
    id: `${scope}.sharing_feedback`,
    defaultMessage: 'Thanks for sharing your feedback, Together we can create seamless experiences.',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
});
