import { defineMessages } from 'react-intl';

export const scope = 'app.components.pages.Empty';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'No Items',
  },
});
