import { defineMessages } from 'react-intl';

export const scope = 'app.components.pages.Forbidden';

export default defineMessages({
  body_title: {
    id: `${scope}.body_title`,
    defaultMessage: 'We are Sorry..',
  },
  body_message: {
    id: `${scope}.body_message`,
    defaultMessage: "The page you're trying to access has restricted access.",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Forbidden!',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Go Home',
  },
});
