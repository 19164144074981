export const topics = [
  { label: 'Scheduling', value: 'Scheduling' },
  { label: 'Workflow List', value: 'Workflow List' },
  { label: 'Candidates', value: 'Candidates' },
  { label: 'General', value: 'General' },
  { label: 'Search', value: 'Search' },
  { label: 'Filter', value: 'Filter' },
  { label: 'Stack ranking', value: 'Stack ranking' },
  { label: 'Login', value: 'Login' },
  { label: 'Evaluation', value: 'Evaluation' },
  { label: 'Assessments', value: 'Assessments' },
];
