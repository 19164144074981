import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.Loading';

export default defineMessages({
  body: {
    id: `${scope}.header`,
    defaultMessage: 'This is taking longer than expected',
  },
});
