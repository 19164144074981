import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.SidePanel';

export default defineMessages({
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  close_panel: {
    id: `${scope}.close_panel`,
    defaultMessage: 'Close panel',
  },
});
