import { defineMessages } from 'react-intl';

export const scope = 'app.components.pages.Error';

export default defineMessages({
  body: {
    id: `${scope}.body`,
    defaultMessage: 'Something went wrong!',
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Something went terribly wrong!',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Go Home',
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: 'Copy to Clipboard',
  },
  clipError: {
    id: `${scope}.clipError`,
    defaultMessage: 'Failed to copy! Check app permissions',
  },
  dave: {
    id: `${scope}.dave`,
    defaultMessage: '- "I am afraid, Dave" - HAL 9000',
  },
});
