/*
 *
 * Action constants
 *
 */

export const REINVITE_CANDIDATE = 'app/Action/REINVITE_CANDIDATE';
export const SCHEDULE_INTERVIEW = 'app/Action/SCHEDULE_INTERVIEW';
export const BULK_INVITE_CANDIDATES = 'app/Action/BULK_INVITE_CANDIDATES';
export const PUBLISH_WORKFLOW = 'app/Action/PUBLISH_WORKFLOW';
export const INVITE_CANDIDATE = 'app/Action/INVITE_CANDIDATE';
export const REMIND_CANDIDATE = 'app/Action/REMIND_CANDIDATE';
export const EXTEND_EVALUATION = 'app/Action/EXTEND_EVALUATION';
export const UPDATE_JOB_DESCRIPTION = 'app/Action/UPDATE_JOB_DESCRIPTION';
export const MOVE_CANDIDATE = 'app/Action/MOVE_CANDIDATE';
export const ASSIGN_PANEL = 'app/Action/ASSIGN_PANEL';
export const REMIND_PANEL = 'app/Action/REMIND_PANEL';
export const EXTEND_LAST_DATE = 'app/Action/EXTEND_LAST_DATE';
export const CANCEL_INTERVIEW = 'app/Action/CANCEL_INTERVIEW';
export const RESCHEDULE_INTERVIEW = 'app/Action/RESCHEDULE_INTERVIEW';
export const ASSIGN_INTERVIEW = 'app/Action/ASSIGN_INTERVIEW';
export const UNPUBLISH_WORKFLOW = 'app/Action/UNPUBLISH_WORKFLOW';
export const UPDATE_STATUS = 'app/Action/UPDATE_STATUS';
export const CREATE_DRIVE = 'app/Action/CREATE_DRIVE';
export const CANCEL_DRIVE_OCCURRENCE = 'app/Action/CANCEL_DRIVE_OCCURRENCE';
export const CREATE_DRIVE_SUCCESS = 'app/Action/CREATE_DRIVE_SUCCESS';
export const EDIT_DRIVE = 'app/Action/EDIT_DRIVE';
export const EDIT_DRIVE_OCCURRENCE = 'app/Action/EDIT_DRIVE_OCCURRENCE';
export const ADD_INTERVIEWER_TO_ROOM = 'app/Action/ADD_INTERVIEWER_TO_ROOM';
export const CANCEL_DRIVE = 'app/Action/CANCEL_DRIVE';
export const CANCEL_DRIVE_SUCCESS = 'app/Action/CANCEL_DRIVE_SUCCESS';
export const REMOVE_INTERVIEWER_FROM_ROOM = 'app/Action/REMOVE_INTERVIEWER_FROM_ROOM';
export const CANCEL_CANDIDATE_INTERVIEW = 'app/Action/CANCEL_CANDIDATE_INTERVIEW';
export const ADD_ROOM = 'app/Action/ADD_ROOM';
export const REMIND_CANDIDATE_INTERVIEW = 'app/Action/REMIND_CANDIDATE_INTERVIEW';
export const UPDATE_SCHEDULE_INTERVIEWER = 'app/Action/UPDATE_SCHEDULE_INTERVIEWER';
export const REMOVE_ROOM_FROM_DRIVE_OCCURRENCE = 'app/Action/REMOVE_ROOM_FROM_DRIVE_OCCURRENCE';
export const REPORT_ISSUE = 'app/Action/REPORT_ISSUE';
export const INITIALIZE = 'app/Action/INITIALIZE';
export const FETCH_TIMEZONES = 'app/Action/FETCH_PROVIDER';
export const STORE_FETCHED_TIMEZONES = 'app/Action/STORE_FETCHED_TIMEZONES';
export const FETCH_PROVIDER = 'app/Action/FETCH_PROVIDER';
export const STORE_FETCHED_PROVIDER = 'app/Action/STORE_FETCHED_PROVIDER';
export const FEATURE_REQUEST = 'app/Action/STORE_FEATURE_REQUEST';
export const UPDATE_USER = 'app/Action/UPDATE_USER';
export const UPDATE_CANDIDATE_STEP = 'app/Action/UPDATE_CANDIDATE_STEP';
export const UPLOAD_FILE = 'app/Action/UPLOAD_FILE';
export const UPDATE_COLLABORATOR = 'app/Action/UPDATE_COLLABORATOR';
export const EXPORT_DATA = 'app/Action/EXPORT_DATA';
export const UPDATE_PASSWORD = 'app/Action/UPDATE_PASSWORD';
export const FETCH_FILE_URL = 'app/Action/FETCH_FILE_URL';
export const CREATE_WORKFLOW = 'app/Action/CREATE_WORKFLOW';
export const DECLINE_INTERVIEW = 'app/Action/DECLINE_INTERVIEW';
export const PROPOSE_NEW_TIME = 'app/Action/PROPOSE_NEW_TIME';
export const ASSIGN_EVALUATOR = 'app/Action/ASSIGN_EVALUATOR';
export const ACCEPT_PROPOSED_NEW_TIME = 'app/Action/ACCEPT_PROPOSED_NEW_TIME';
export const DECLINE_PROPOSED_NEW_TIME = 'app/Action/DECLINE_PROPOSED_NEW_TIME';
export const DELETE_AVAILABILITY = 'app/Action/DELETE_AVAILABILITY';
export const ADD_AVAILABILITY = 'app/Action/ADD_AVAILABILITY';
export const UPDATE_MEETING_PARTICIPATION_STATUS = 'app/Action/UPDATE_MEETING_PARTICIPATION_STATUS';
export const COMPLETE_MEETING = 'app/Action/COMPLETE_MEETING';
export const ADD_SCH_OVERRIDE = 'app/Action/ADD_SCH_OVERRIDE';
export const UPDATE_DRIVE_MEETING_PARTICIPATION_STATUS = 'app/Action/UPDATE_DRIVE_MEETING_PARTICIPATION_STATUS';
export const UPDATE_DRIVE_MEMBER_STATUS = 'app/Action/UPDATE_DRIVE_MEMBER_STATUS';
export const DELETE_SCHEDULE_OVERRIDE = 'app/Action/DELETE_SCHEDULE_OVERRIDE';
export const RESCHEDULE_MEETING = 'app/Action/RESCHEDULE_MEETING';
export const GENERATE_VOICE_TOKEN = 'app/Action/GENERATE_VOICE_TOKEN';
export const CONFIRM_SLOT_BOOKING = 'app/Action/CONFIRM_SLOT_BOOKING';
