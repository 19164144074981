import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.SideNavigation.ReportIssue';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Report an Issue',
  },
  summary: {
    id: `${scope}.summary`,
    defaultMessage: 'What can we do to help you?',
  },
  add_screenshot: {
    id: `${scope}.add_screenshot`,
    defaultMessage: 'Add Screenshot (Optional)',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  upload_picture: {
    id: `${scope}.upload_picture`,
    defaultMessage: 'Upload Picture',
  },
  or: {
    id: `${scope}.or`,
    defaultMessage: 'or',
  },
  capture_screenshot: {
    id: `${scope}.capture_screenshot`,
    defaultMessage: 'Capture Screenshot',
  },
  success_message_title: {
    id: `${scope}.success_message_title`,
    defaultMessage: 'Reporting issue successful',
  },
  error_message_title: {
    id: `${scope}.error_message_title`,
    defaultMessage: 'Reporting issue failed.',
  },
  error_message_summary: {
    id: `${scope}.error_message_summary`,
    defaultMessage: 'Uh-oh! Reporting issue has failed.',
  },
  success_title: {
    id: `${scope}.success_title`,
    defaultMessage: "We've got your back!",
  },
  success_summary: {
    id: `${scope}.success_summary`,
    defaultMessage: 'Our team will get back to you soon.',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
});
