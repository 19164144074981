import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.Modal';

export default defineMessages({
  success: {
    id: `${scope}.success`,
    defaultMessage: 'Ok',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
});
