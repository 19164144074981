import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.Status';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Status component!',
  },
  Completed: {
    id: `${scope}.Completed`,
    defaultMessage: 'Completed',
  },
  'No show': {
    id: `${scope}.no_show`,
    defaultMessage: 'No show',
  },
  Inactive: {
    id: `${scope}.in_active`,
    defaultMessage: 'Inactive',
  },
  'In Progress': {
    id: `${scope}.in_progress`,
    defaultMessage: 'In Progress',
  },
  Draft: {
    id: `${scope}.draft`,
    defaultMessage: 'Draft',
  },
  Cancelled: {
    id: `${scope}.cancelled`,
    defaultMessage: 'Cancelled',
  },
  Ongoing: {
    id: `${scope}.ongoing`,
    defaultMessage: 'Ongoing',
  },
  "Couldn't allocate": {
    id: `${scope}.cannot_allocate`,
    defaultMessage: `Couldn't allocate`,
  },
  Invited: {
    id: `${scope}.invited`,
    defaultMessage: 'Invited',
  },
  Selected: {
    id: `${scope}.selected`,
    defaultMessage: 'Selected',
  },
  Hold: {
    id: `${scope}.hold`,
    defaultMessage: 'Hold',
  },
  Suspended: {
    id: `${scope}.suspended`,
    defaultMessage: 'Suspended',
  },
  'Turned down': {
    id: `${scope}.turned_down`,
    defaultMessage: 'Turned down',
  },
  Tentative: {
    id: `${scope}.tentative`,
    defaultMessage: 'Tentative',
  },
  Accepted: {
    id: `${scope}.accepted`,
    defaultMessage: 'Accepted',
  },
  DECLINED: {
    id: `${scope}.declined`,
    defaultMessage: 'Declined',
  },
  'Proposed new time': {
    id: `${scope}.proposed_new_time`,
    defaultMessage: 'Proposed new time',
  },
  'No Response': {
    id: `${scope}.no_response`,
    defaultMessage: 'No Response',
  },
  'Declined new time proposed': {
    id: `${scope}.declined_new_time`,
    defaultMessage: 'Declined new time proposed',
  },
  Registered: {
    id: `${scope}.registered`,
    defaultMessage: 'Registered',
  },
  Booked: {
    id: `${scope}.booked`,
    defaultMessage: 'Booked',
  },
  Ready: {
    id: `${scope}.ready`,
    defaultMessage: 'Ready',
  },
  Evaluated: {
    id: `${scope}.evaluated`,
    defaultMessage: 'Evaluated',
  },
  // proctor status
  Success: {
    id: `${scope}.success`,
    defaultMessage: 'Success',
  },
  Warning: {
    id: `${scope}.warning`,
    defaultMessage: 'Warning',
  },
  Failed: {
    id: `${scope}.failed`,
    defaultMessage: 'Failed',
  },
  Processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing',
  },
  // wftJobStatus
  Open: {
    id: `${scope}.open`,
    defaultMessage: 'Open',
  },
  Filled: {
    id: `${scope}.filled`,
    defaultMessage: 'Filled',
  },
  Closed: {
    id: `${scope}.closed`,
    defaultMessage: 'Closed',
  },

  // Drive GuestInvited
  Scheduled: {
    id: `${scope}.scheduled`,
    defaultMessage: 'Scheduled',
  },

  'Not joined': {
    id: `${scope}.not_joined`,
    defaultMessage: 'Not joined',
  },
  Waiting: {
    id: `${scope}.waiting`,
    defaultMessage: 'Waiting',
  },
  Joined: {
    id: `${scope}.joined`,
    defaultMessage: 'Joined',
  },
});
