/**
 *
 * Checkbox
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from 'components/base/Status/messages';

const sizeMap = {
  xs: 'h-3 w-3',
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
  lg: 'h-5 w-5',
};
const textMap = {
  xs: 'text-xs leading-0',
  sm: 'text-sm leading-1',
  md: 'text-md leading-2',
  lg: 'text-lg leading-4',
};

export function Checkbox(props) {
  const { id, name, onChange, size, label, disabled, checked, value } = props;

  const handleChange = () => {
    onChange(value);
  };

  return (
    <div role="button" className={classNames('flex align-middle items-center')}>
      <input
        data-testid={`checkbox-${id}`}
        id={`checkbox-${id}`}
        name={`checkbox-${name}`}
        type="checkbox"
        onChange={handleChange}
        className={classNames(
          get(sizeMap, size, 'h3 w-3'),
          disabled ? 'text-zinc-300 border-zinc-300' : 'text-blue-600  border-gray-500 cursor-pointer',
          'rounded-sm',
        )}
        disabled={disabled}
        checked={checked}
        value={value}
      />
      {label && (
        <label
          htmlFor={`checkbox-${id}`}
          className={classNames(
            'ml-2',
            get(textMap, size, 'text-base'),
            disabled ? 'text-zinc-300' : 'text-gray-700',
            !disabled && 'cursor-pointer',
          )}
        >
          {get(messages, label)?.id ? <FormattedMessage {...get(messages, label)} /> : label}
        </label>
      )}
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xs']),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Checkbox.defaultProps = {
  size: 'md',
};
export default memo(Checkbox);
