import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.SideNavigation.GetHelp';

export default defineMessages({
  report_an_issue: {
    id: `${scope}.report_an_issue`,
    defaultMessage: 'Report an Issue',
  },
  request_a_feature: {
    id: `${scope}.request_a_feature`,
    defaultMessage: 'Request a Feature',
  },
  documentation: {
    id: `${scope}.documentation`,
    defaultMessage: 'Documentation',
  },
});
