import React from 'react';
import { filter, find, includes, isNaN, lowerCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { selfScheduleSuccessfulAction } from 'utils/mixpanelActions';
import messages from './messages';
import { getFormattedStepType, stepTypes } from '../stepTypes';

export const actionTypeConstants = {
  REINVITE_CANDIDATE: 'REINVITE_CANDIDATE',
  SCHEDULE_INTERVIEW: 'SCHEDULE_INTERVIEW',
  PUBLISH_WORKFLOW: 'PUBLISH_WORKFLOW',
  INVITE_CANDIDATE: 'INVITE_CANDIDATE',
  BULK_INVITE_CANDIDATE: 'BULK_INVITE_CANDIDATE',
  REMIND_CANDIDATE: 'REMIND_CANDIDATE',
  EXTEND_EVALUATION: 'EXTEND_EVALUATION',
  UPDATE_JOB_DESCRIPTION: 'UPDATE_JOB_DESCRIPTION',
  MOVE_CANDIDATE: 'MOVE_CANDIDATE',
  ASSIGN_PANEL: 'ASSIGN_PANEL',
  REMIND_PANEL: 'REMIND_PANEL',
  EXTEND_LASTDATE: 'EXTEND_LASTDATE',
  CANCEL_INTERVIEW: 'CANCEL_INTERVIEW',
  RESCHEDULE_INTERVIEW: 'RESCHEDULE_INTERVIEW',
  ASSIGN_INTERVIEW_PANEL: 'ASSIGN_INTERVIEW_PANEL',
  UNPUBLISH_WORKFLOW: 'UNPUBLISH_WORKFLOW',
  CREATE_WORKFLOW: 'CREATE_WORKFLOW',
  ADD_COLLABORATORS: 'ADD_COLLABORATORS',
  REMOVE_COLLABORATORS: 'REMOVE_COLLABORATORS',
  ASSIGN_EVALUATOR: 'ASSIGN_EVALUATOR',
};

export const actionTypes = [
  {
    key: actionTypeConstants.CREATE_WORKFLOW,
    showCount: false,
    description: () => <FormattedMessage {...messages.CREATE_WORKFLOW} />,
    rowContent: () => 'Workflow created',
    title: '',
  },
  {
    key: actionTypeConstants.ADD_COLLABORATORS,
    showCount: false,
    description: () => <FormattedMessage {...messages.ADD_COLLABORATORS} />,
    rowContent: () => 'Collaborator added',
    title: '',
  },
  {
    key: actionTypeConstants.ASSIGN_EVALUATOR,
    showCount: false,
    description: () => <FormattedMessage {...messages.ASSIGN_EVALUATOR} />,
    rowContent: () => 'Assign Evaluator',
    title: '',
  },
  {
    key: actionTypeConstants.REMOVE_COLLABORATORS,
    showCount: false,
    description: () => <FormattedMessage {...messages.REMOVE_COLLABORATORS} />,
    rowContent: () => 'Collaborator removed',
    title: '',
  },
  {
    key: actionTypeConstants.REINVITE_CANDIDATE,
    showCount: true,
    description: count => <FormattedMessage {...messages.REINVITE_CANDIDATE} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Candidates reinvited`;
      }
      return `${count} Candidate reinvited`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.BULK_INVITE_CANDIDATE,
    showCount: true,
    description: count => <FormattedMessage {...messages.INVITE_CANDIDATE} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Candidates invited`;
      }
      return `${count} Candidate invited`;
    },
    title: 'Invite',
    templates: {
      inProgress: ({ totalCount, workflowName, stepName }) => ({
        message: {
          title: 'Invite in progress',
          summary: (
            <span>
              {totalCount > 1 ? 'Candidates are' : 'Candidate is'} invited in the background against{' '}
              <span className="font-semibold">
                {workflowName}-{stepName}
              </span>
              . <FormattedMessage {...messages?.notify} />
            </span>
          ),
        },
        variant: 'processing',
      }),
      error: ({ errorCount, workflowName, stepName }) => ({
        message: {
          title: 'Invite failed!',
          summary: (
            <span>
              <FormattedMessage {...messages?.invite_against} />
              <span className="font-semibold">
                {workflowName}-{stepName}
              </span>{' '}
              for {errorCount} {errorCount > 1 ? 'candidates' : 'candidate'} has failed.
            </span>
          ),
        },
        variant: 'danger',
      }),
      success: ({ successCount, workflowName, stepName }) => ({
        message: {
          title: 'Invite sent successfully!',
          summary: (
            <span>
              <FormattedMessage {...messages.invitation_for} /> {successCount}{' '}
              {successCount > 1 ? 'candidates' : 'candidate'} <FormattedMessage {...messages.against} />
              <span className="font-semibold">
                {workflowName}-{stepName}
              </span>
              <FormattedMessage {...messages.completed_success} />
            </span>
          ),
        },
        variant: 'success',
      }),
      partialSuccess: ({ errorCount, totalCount, workflowName, stepName }) => ({
        message: {
          title: 'Invite incomplete!',
          summary: (
            <span>
              {errorCount} <FormattedMessage {...messages?.out_of} /> {totalCount}{' '}
              {totalCount > 1 ? 'candidates' : 'candidate'} <FormattedMessage {...messages.notInvited} />
              <span className="font-semibold">
                {workflowName}-{stepName}
              </span>
            </span>
          ),
        },
        variant: 'danger',
      }),
    },
  },
  {
    key: actionTypeConstants.SCHEDULE_INTERVIEW,
    showCount: true,
    description: count => <FormattedMessage {...messages.SCHEDULE_INTERVIEW} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Interviews scheduled`;
      }
      return `${count} Interview scheduled`;
    },
    title: 'Schedule',
    mixPanelAction: selfScheduleSuccessfulAction,
    templates: {
      inProgress: ({ totalCount, workflowName, stepName }) => ({
        message: {
          title: <FormattedMessage {...messages?.scheduling_progress} />,
          summary: (
            <span>
              {totalCount > 1 ? (
                <FormattedMessage {...messages?.multiple_candidates} />
              ) : (
                <FormattedMessage {...messages?.single_candidate} />
              )}
              <FormattedMessage {...messages?.scheduled_background} />
              <span className="font-semibold">
                {workflowName}-{stepName}
              </span>
              . <FormattedMessage {...messages?.notify} />
            </span>
          ),
        },
        variant: 'processing',
      }),
      error: ({ errorCount, workflowName, stepName }) => ({
        message: {
          title: 'Scheduling failed!',
          summary: (
            <span>
              <FormattedMessage {...messages?.scheduling_against} />
              <span className="font-semibold">
                {workflowName}-{stepName}
              </span>
              <FormattedMessage {...messages?.for_label} /> {errorCount} {errorCount > 1 ? 'candidates' : 'candidate'}
              <FormattedMessage {...messages?.has_failed} />
            </span>
          ),
        },
        variant: 'danger',
      }),
      success: ({ successCount, workflowName, stepName }) => ({
        message: {
          title: <FormattedMessage {...messages.scheduling_successful} />,
          summary: (
            <span>
              <FormattedMessage {...messages?.scheduling} />
              {successCount}{' '}
              {successCount > 1 ? (
                <FormattedMessage {...messages?.multiple_candidates} />
              ) : (
                <FormattedMessage {...messages?.single_candidate} />
              )}{' '}
              <FormattedMessage {...messages?.against} />
              <span className="font-semibold">
                {workflowName}-{stepName}
              </span>
              <FormattedMessage {...messages?.completed_success} />
            </span>
          ),
        },
        variant: 'success',
      }),
      partialSuccess: ({ errorCount, totalCount, workflowName, stepName }) => ({
        message: {
          title: 'Scheduling incomplete!',
          summary: (
            <span>
              {errorCount} <FormattedMessage {...messages?.out_of} /> {totalCount}{' '}
              {totalCount > 1 ? 'candidates' : 'candidate'} <FormattedMessage {...messages?.not_scheduled} />
              <span className="font-semibold">
                {workflowName}-{stepName}
              </span>
            </span>
          ),
        },
        variant: 'danger',
      }),
    },
  },
  {
    key: actionTypeConstants.PUBLISH_WORKFLOW,
    showCount: false,
    description: () => <FormattedMessage {...messages.PUBLISH_WORKFLOW} />,
    rowContent: () => 'Workflow published',
    title: '',
  },
  {
    key: actionTypeConstants.INVITE_CANDIDATE,
    showCount: true,
    description: count => <FormattedMessage {...messages.INVITE_CANDIDATE} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Candidates invited`;
      }
      return `${count} Candidate invited`;
    },
    title: 'Invite',
  },
  {
    key: actionTypeConstants.REMIND_CANDIDATE,
    showCount: true,
    description: count => <FormattedMessage {...messages.REMIND_CANDIDATE} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Candidates reminded`;
      }
      return `${count} Candidate reminded`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.EXTEND_EVALUATION,
    showCount: true,
    description: count => <FormattedMessage {...messages.EXTEND_EVALUATION} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Evaluation dates extended`;
      }
      return `${count} Evaluation date extended`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.UPDATE_JOB_DESCRIPTION,
    showCount: false,
    description: () => <FormattedMessage {...messages.UPDATE_JOBDESCRIPTION} />,
    rowContent: () => `Description updated`,
    title: '',
  },
  {
    key: actionTypeConstants.MOVE_CANDIDATE,
    showCount: true,
    description: count => <FormattedMessage {...messages.MOVE_CANDIDATE} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Candidates moved`;
      }
      return `${count} Candidate moved`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.ASSIGN_PANEL,
    showCount: true,
    description: count => <FormattedMessage {...messages.ASSIGN_PANEL} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Evaluators assigned`;
      }
      return `${count} Evaluator assigned`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.REMIND_PANEL,
    showCount: true,
    description: count => <FormattedMessage {...messages.REMIND_PANEL} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Evaluators reminded`;
      }
      return `${count} Evaluator reminded`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.EXTEND_LASTDATE,
    showCount: true,
    description: count => <FormattedMessage {...messages.EXTEND_LASTDATE} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Candidates provided extension`;
      }
      return `${count} Candidate provided extension`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.CANCEL_INTERVIEW,
    showCount: true,
    description: count => <FormattedMessage {...messages.CANCEL_INTERVIEW} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Interviews canceled`;
      }
      return `${count} Interview canceled`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.RESCHEDULE_INTERVIEW,
    showCount: true,
    description: count => <FormattedMessage {...messages.RESCHEDULE_INTERVIEW} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Interviews rescheduled`;
      }
      return `${count} Interview rescheduled`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.ASSIGN_INTERVIEW_PANEL,
    showCount: true,
    description: count => <FormattedMessage {...messages.ASSIGN_INTERVIEW_PANEL} values={{ value: count }} />,
    rowContent: count => {
      if (count > 1) {
        return `${count} Candidates assigned interviewers`;
      }
      return `${count} Candidate assigned interviewers`;
    },
    title: '',
  },
  {
    key: actionTypeConstants.UNPUBLISH_WORKFLOW,
    showCount: false,
    description: () => <FormattedMessage {...messages.UNPUBLISH_WORKFLOW} />,
    rowContent: () => `Workflow unpublished`,
    title: '',
  },
];

export const actions = [
  {
    title: 'Invite',
    component: 'invite',
    viewType: 'panel',
    actionType: find(actionTypes, { key: actionTypeConstants.BULK_INVITE_CANDIDATE }),
    category: ['all', stepTypes.ASSESSMENT, stepTypes.INTERVIEW, stepTypes.SCREENING],
    sortOrder: {
      all: 1,
      [stepTypes.ASSESSMENT]: 1,
      [stepTypes.INTERVIEW]: 1,
      [stepTypes.SCREENING]: 1,
    },
    defaultFor: ['all', stepTypes.ASSESSMENT, stepTypes.INTERVIEW, stepTypes.SCREENING],
  },
  {
    title: 'Schedule',
    component: 'schedule',
    viewType: 'panel',
    actionType: find(actionTypes, { key: actionTypeConstants.SCHEDULE_INTERVIEW }),
    category: ['all', stepTypes.ASSESSMENT, stepTypes.INTERVIEW, stepTypes.SCREENING],
    sortOrder: {
      all: 2,
      [stepTypes.ASSESSMENT]: 2,
      [stepTypes.INTERVIEW]: 2,
      [stepTypes.SCREENING]: 2,
    },
    defaultFor: ['all', stepTypes.ASSESSMENT, stepTypes.INTERVIEW, stepTypes.SCREENING],
  },
  {
    title: 'Cancel',
    component: 'cancel',
    viewType: 'modal',
    actionType: find(actionTypes, { key: actionTypeConstants.CANCEL_INTERVIEW }),
    category: [stepTypes.INTERVIEW],
    sortOrder: {
      [stepTypes.INTERVIEW]: 5,
    },
  },
  {
    title: 'Status',
    component: 'updateStatus',
    viewType: 'panel',
    actionType: find(actionTypes, { key: actionTypeConstants.SCHEDULE_INTERVIEW }),
    category: [stepTypes.ASSESSMENT, stepTypes.INTERVIEW, stepTypes.SCREENING],
    sortOrder: {
      [stepTypes.ASSESSMENT]: 5,
      [stepTypes.INTERVIEW]: 4,
      [stepTypes.SCREENING]: 5,
    },
  },
  {
    title: 'Extend',
    component: 'extend',
    viewType: 'panel',
    actionType: find(actionTypes, { key: actionTypeConstants.SCHEDULE_INTERVIEW }),
    category: [stepTypes.ASSESSMENT],
    sortOrder: {
      [stepTypes.ASSESSMENT]: 7,
      [stepTypes.SCREENING]: 2,
    },
  },
  {
    title: 'Assign',
    component: 'assign',
    viewType: 'panel',
    actionType: find(actionTypes, { key: actionTypeConstants.SCHEDULE_INTERVIEW }),
    category: [stepTypes.ASSESSMENT, stepTypes.SCREENING],
    sortOrder: {
      [stepTypes.ASSESSMENT]: 4,
      [stepTypes.SCREENING]: 4,
    },
  },
  {
    title: 'Assign Evaluator',
    component: 'assignEvaluator',
    viewType: 'modal',
    actionType: find(actionTypes, { key: actionTypeConstants.ASSIGN_EVALUATOR }),
    category: [stepTypes.INTERVIEW, stepTypes.SCREENING],
    sortOrder: {
      [stepTypes.INTERVIEW]: 5,
      [stepTypes.SCREENING]: 5,
    },
  },
  {
    title: 'Remind',
    component: 'remind',
    viewType: 'modal',
    subActions: [
      { type: 'all', title: 'All', actionType: find(actionTypes, { key: 'REMIND' }) },
      {
        type: 'candidate',
        title: 'Candidate only',
        actionType: find(actionTypes, { key: actionTypeConstants.REMIND_CANDIDATE }),
      },
      {
        type: 'evaluator',
        title: 'Evaluator only',
        actionType: find(actionTypes, { key: actionTypeConstants.REMIND_PANEL }),
      },
    ],
    category: ['all', stepTypes.ASSESSMENT, stepTypes.INTERVIEW, stepTypes.SCREENING],
    sortOrder: {
      all: 3,
      [stepTypes.ASSESSMENT]: 3,
      [stepTypes.INTERVIEW]: 3,
      [stepTypes.SCREENING]: 3,
    },
  },
  {
    title: 'Reinvite',
    component: 'reinvite',
    viewType: 'panel',
    actionType: find(actionTypes, { key: actionTypeConstants.REINVITE_CANDIDATE }),
    category: [stepTypes.ASSESSMENT],
    sortOrder: {
      [stepTypes.ASSESSMENT]: 6,
    },
  },
];

export const getDefaultWsActions = stepType =>
  filter(actions, action => includes(action.defaultFor, getFormattedStepType(stepType)));

export const getAdditionalActions = stepType =>
  filter(
    actions,
    action =>
      !includes(action.defaultFor, getFormattedStepType(stepType)) &&
      includes(action.category, getFormattedStepType(stepType)),
  );

export function getDescription(activity) {
  const actionType = find(actionTypes, at => lowerCase(at?.key || '') === lowerCase(activity?.activity_type));
  if (actionType) {
    if (actionType?.description) {
      if (actionType?.showCount) {
        return {
          description: actionType.description(
            activity?.success_count && !isNaN(activity?.success_count) ? Number(activity?.success_count) : 0,
          ),
          rowContent: actionType.rowContent(
            activity?.success_count && !isNaN(activity?.success_count) ? Number(activity?.success_count) : 0,
          ),
        };
      }
      return {
        description: actionType.description(),
        rowContent: actionType.rowContent(),
      };
    }
    return {
      description: activity.activity_type,
      rowContent: activity.activity_type,
    };
  }
  return {
    description: activity.activity_type,
    rowContent: activity.activity_type,
  };
}

export function getActionType(type) {
  return find(actions, action => action.actionType.key === type);
}
