import React from 'react';
import { ConfirmDialog } from 'components/base/ConfirmDialog';
import SuccessScreenContent from './Content';

const SuccessScreen = ({ onClose }) => (
  <>
    <ConfirmDialog
      open
      icon
      handleClose={onClose}
      title={<span></span>}
      isCustomContent
      disabledAutoClose
      customContent={
        <div className="w-[432px] h-[410px]">
          <SuccessScreenContent onClose={onClose} />
        </div>
      }
    />
  </>
);

export default SuccessScreen;
